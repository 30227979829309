//import { reviewApiAuthorizationKey } from "../global/constants";

async function fetchReviews(propertyId) {
  let response = await fetch(
    `https://review.staah.net/wsdl/reviewlist.php?request_for=getReviews&StaahID=${propertyId}`
  );
  return await response.json();
}

async function fetchWatchMyRate(
  propertyId,
  startDate,
  endDate,
  bookingEngine,
  accessKey
) {
  const url =
    bookingEngine === "I"
      ? `https://cp.staah.net/common-cgi/motels/admin/MinRoomRate_wmrnew_validity.pl?propertyId=${propertyId}&checkin_date=${startDate}&checkout_date=${endDate}`
      : `https://watchmyrate.com/wmrwidgetData_react.php?propertyId=${propertyId}&checkin_cmdate=${startDate}&checkout_cmdate=${endDate}&access_key=${accessKey}&theme=react&domain_name=homes`;

  let response = await fetch(url);
  return await response.json();
}

async function fetchDailyOffer(propertyId) {
  let response = await fetch(
    `https://cp.staah.net/common-cgi/motels/admin/daily_offer_new.pl?motelid=${propertyId}`
  );
  return await response.json();
}

export { fetchReviews, fetchWatchMyRate, fetchDailyOffer };
