import React from "react";
import styled from "styled-components";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookRoomUrl,
} from "../../global/constants";
import constant from "./constant";
import media from "../../global/media";

const AccommodationImageWrap = styled.div`
  background: #fff;
  text-decoration: none;
  color: ${constant.bodyColor};
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  width: 100%;
  .card-img-wrapper,
  .card-body {
    width: 100%;
    ${media.sm} {
      width: 50%;
    }

    padding: 20px;
    .card-desc {
      color: #000;
    }
  }

  .card-img {
    overflow: hidden;
    position: relative;
    width: 100%;
    background-size: cover;
    .card-img-top {
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      transition: 1s;
      pointer-events: none;
      width: 100%;
      height: 100%;
      &:nth-child(1) {
        opacity: 1;
        filter: blur(0);
        transform: translateX(0%) scaleX(1);
        position: relative;
      }
      &:nth-child(2) {
      }
    }
    &:hover {
      .card-img-top:nth-child(2) {
        opacity: 0;
        filter: blur(10px);
        transform: translateX(-50%) scaleX(2);
      }
      .card-img-top:nth-child(1) {
        opacity: 1;
        filter: blur(0);
        transform: translateX(0%) scaleX(1);
      }
    }
  }
  position: relative;

  h3 {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-style: normal;
    text-decoration: none;
    word-break: normal;
    ${media.sm} {
      font-size: 2rem;
    }
  }
  .accommodationBtn {
    display: flex;
    margin-top: 40px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    a {
      margin-right: 20px;
      margin-bottom: 30px;
      display: inline-block;
      &:last-child {
        color: #fff;
      }
    }
  }
`;

const LinkWrapper = styled.a`
  position: relative;

  text-decoration: none !important;

  font-size: 16px;

  padding: 10px 30px;
  border-radius: 5px;
  background: ${constant.primaryColor};
  &:hover {
    background: ${constant.blackColor};
  }

  &:first-child {
    background: none;
    text-decoration: none;

    border: 0;
    padding: 0;
    font-style: italic;
    &:hover {
      color: ${constant.primaryColor};
    }
  }
`;
const RoomDetailHomePageComponent = ({ value, mainSlugName }) => (
  <AccommodationImageWrap>
    <LinkWrapper
      onClick={() => window.scrollTo(0, 0)}
      href={mainSlugName + value.RoomTypeName.slugName}
      className="card-img-wrapper"
    >
      {value.RoomTypeImages.Images.length > 0 ? (
        <div className="card-img">
          <img
            src={sliderImageURL + value.RoomTypeImages.Images[1]}
            alt=""
            className="card-img-top"
          />
          <img
            src={sliderImageURL + value.RoomTypeImages.Images[0]}
            alt=""
            className="card-img-top"
          />
        </div>
      ) : (
        <img src={noImageUrl} class="card-img-top" alt="" />
      )}
    </LinkWrapper>
    <div className="card-body">
      <LinkWrapper
        onClick={() => window.scrollTo(0, 0)}
        href={mainSlugName + value.RoomTypeName.slugName}
      >
        <h3>{value.RoomTypeName.section}</h3>
        <div
          className="card-desc"
          dangerouslySetInnerHTML={{
            __html: decodeContent(value.RoomTypeDescription),
          }}
        ></div>
      </LinkWrapper>
      <div className="accommodationBtn">
        <LinkWrapper
          onClick={() => window.scrollTo(0, 0)}
          href={mainSlugName + value.RoomTypeName.slugName}
        >
          more >>
        </LinkWrapper>
        <LinkWrapper href={`${bookRoomUrl(value.RoomcatgId)}`} target="_blank">
          Book Now
        </LinkWrapper>
      </div>
    </div>
  </AccommodationImageWrap>
);
export default RoomDetailHomePageComponent;
