import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import CapacityComponent from "../CapacityComponent";
import { formatDate } from "../functions";
import { bookNowUrl } from "../constants";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import data from "../../../hotelData.json";

const BookingWidget = styled.div`
  h2 {
    font-size: 25px;
  }
  .datepicker-input {
    width: 100%;
  }
  button {
    cursor: pointer;
  }
`;

const RoomContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const AdultContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;

const ChildContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;

const BookingWidgetFlexComponent = ({ buttonLabel }) => {
  // console.log(BEWidgetFlags);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [pickerOpen, setPickerOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [roomBox, setRoomBox] = useState(false);
  const [monthShownDatepicker, setMonthShownDatepicker] = useState(0);

  // console.log(BEWidgetFlags);
  const propertyId = data.propertyDetails.propertyID;
  const whiteLabelURL = data.whiteLabelURL;
  const BEWidgetFlags = data.BEWidgetFlags;
  const bookingEngine = data.bookingEngine;
  const nightSearch = BEWidgetFlags.NoOfNightSearch;
  const VacationRental = BEWidgetFlags.VacationRental;
  const maxAdult = BEWidgetFlags.MaxAdult;
  const maxChild = BEWidgetFlags.MaxChild;
  const currency = BEWidgetFlags.currency;

  const [room, setRooms] = useState([
    {
      adult: 1,
      child: 0,
    },
  ]);
  const [roomString, setRoomString] = useState([
    {
      adult: 1,
      child: 0,
    },
  ]);

  // const handleInput = (e) => {
  //   setPromoCode(e.target.value);
  // };

  const [person, setPerson] = useState({
    adult: "1",
    child: "0",
    promoCode: "",
    flexiDate: "",
    roomTypes: "all",
    m_currency: data.BEWidgetFlags.currency[0],
  });

  const handleStartDate = (date) => {
    setStartDate(date);
    setEndDate(addDays(date, nightSearch));
    setPickerOpen(true);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
    setPickerOpen(false);
  };
  const handleInput = (e) => {
    // if(bookingEngine === "I"){

    // setPromoCode(e.target.value);
    // }else{
    const { name, value } = e.target;
    setPerson((initial) => {
      return {
        ...initial,
        [name]: value,
      };
    });
    // }
  };
  const handleCheckbox = (e) => {
    // if(bookingEngine === "I"){

    // setPromoCode(e.target.value);
    // }else{
    const { name, checked } = e.target;

    setPerson((initial) => {
      return {
        ...initial,
        [name]: checked,
      };
    });
    // }
  };

  const handleCheckAvailability = () => {
    const flexiString = person.flexiDate ? `&display=flexi` : "";
    if (data.bookingEngine === "I") {
      //for instant Engine

      let roomParameter = "";
      room.map((item, index) => {
        roomParameter += `&adult${index}=${item.adult}&child${index}=${item.child}`;
      });

      window
        .open(
          `${bookNowUrl(flexiString ? "1" : "")}&checkIn=${formatDate(
            startDate
          )}&checkOut=${formatDate(endDate)}&noofrooms=${room.length
          }${roomParameter}&ccode=${person.promoCode}${flexiString}`,
          "_blank"
        )
        .focus();
    } else {
      const currencyString =
        BEWidgetFlags.Multycurrency && BEWidgetFlags.Multycurrency === "Y"
          ? "&m_currency=" + person.m_currency
          : "";

      const promocodeString =
        person.promoCode !== "" ? `&promoCode=${person.promoCode}` : "";

      const personString =
        BEWidgetFlags.AdultChildPermission === "Y"
          ? `&roomadult=${person.adult}&roomchild=${person.child}`
          : "";

      const checkInOutString = `checkIn=${formatDate(
        startDate
      )}&checkOut=${formatDate(endDate)}`;

      const vacationString =
        VacationRental && VacationRental === "Y"
          ? person.roomTypes === "all"
            ? `indexpackdetail?`
            : `indexpack?roomTypeId=${person.roomTypes}&`
          : `indexpackdetail?`;

      window
        .open(
          BEWidgetFlags.followTheEye && BEWidgetFlags.followTheEye === "Y"
            ? bookNowUrl() +
            `&checkin_date=${formatDate(
              startDate
            )}&checkout_date=${formatDate(endDate)}`
            : `https://${whiteLabelURL}/bookings/${vacationString}${checkInOutString}${flexiString}${personString}${promocodeString}${currencyString}&individual=true&propertyId=${propertyId}`,
          "_blank"
        )
        .focus();
    }
  };

  const findWidth = () => {
    if (document.body.clientWidth <= 968) {
      setMonthShownDatepicker(0);
    } else {
      setMonthShownDatepicker(1);
    }
  };

  useEffect(() => findWidth(), []);


  // useEffect(()=>{
  //     document.getElementsByClassName('datepicker-input').setAttribute('readonly', 'readonly');
  // },[])

  return (
    <BookingWidget
      className={`bookingWidget widgetBgOpacity ${bookingEngine === "I" ? "" : "max"
        }`}
    >
      <div className="heading">Book Your Stay</div>
      <div className={`bookingengine show`}>
        {VacationRental && VacationRental === "Y" && (
          <div className="roomTypesWrapper">
            <div className="roomType-heading">Listing</div>
            <div className="roomTypes-inputWrapper">
              <select
                name="roomTypes"
                id="roomTypes"
                tabIndex="-1"
                onChange={handleInput}
              >
                <option value="all">All</option>
                {data.roomTypes.roomType.map((item, index) => (
                  <option value={item.RoomcatgId}>
                    {item.RoomTypeName.section}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="checkInWrapper">
          <div className="checkInOut-heading">Arrival</div>
          <DatePicker
            type="date"
            selected={startDate}
            className="datepicker-input mobile"
            onChange={(date) => handleStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            monthsShown={monthShownDatepicker ? 2 : 1}
            minDate={new Date()}
            dateFormat={"dd-MMM-yyyy"}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />

        </div>
        <div className="checkOutWrapper">
          <div className="checkInOut-heading">Departure</div>
          <DatePicker
            //ref={endRef}
            type="date"
            selected={endDate}
            className="datepicker-input mobile"
            onKeyDown={(e) => e.preventDefault()}
            onChange={(date) => handleEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat={"dd-MMM-yyyy"}
            monthsShown={monthShownDatepicker ? 2 : 1}
          />
        </div>

        {BEWidgetFlags.AdultChildPermission &&
          BEWidgetFlags.AdultChildPermission === "Y" && (
            <div className="roomsWrapper">
              {bookingEngine === "I" ? (
                <div className="capacityWrapper">
                  <div className="rooms-heading">Rooms</div>
                  <CapacityComponent
                    roomBox={roomBox}
                    setRoomBox={setRoomBox}
                    room={room}
                    setRooms={setRooms}
                    roomString={roomString}
                    setRoomString={setRoomString}
                    maxAdult={maxAdult}
                    maxChild={maxChild}
                    AdultChildPermission={BEWidgetFlags.AdultChildPermission}
                  />
                </div>
              ) : (
                <RoomContainer className="roomsInsideWrapper">
                  <AdultContainer className="adultWrapper">
                    <label className="adult-heading" htmlFor="adult">
                      Adult
                    </label>
                    <select
                      name="adult"
                      id="adult"
                      tabIndex="-1"
                      onChange={handleInput}
                    >
                      {Array.from({ length: maxAdult }, (value, key) => (
                        <option key={key} value={key + 1}>
                          {key + 1}
                        </option>
                      ))}
                    </select>
                  </AdultContainer>
                  <ChildContainer className="childWrapper">
                    <label className="child-heading" htmlFor="child">
                      {" "}
                      Child
                    </label>
                    {maxChild > 0 ? (
                      <select
                        name="child"
                        id="child"
                        tabIndex="-1"
                        onChange={handleInput}
                      >
                        <option key={0} value={0}>0</option>
                        {Array.from({ length: maxChild }, (value, key) => (
                          <option key={key} value={key + 1}>
                            {key + 1}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select name="child" id="child" tabIndex="-1" disabled>
                        <option selected>Child Not Allowed</option>
                      </select>
                    )}
                  </ChildContainer>
                </RoomContainer>
              )}
            </div>
          )}

        <div className="promoWrapper">
          <div className="promo-heading">Promo code</div>
          <div className="promo-code-wrapper">
            <input
              type="text"
              onChange={handleInput}
              value={person.promoCode}
              className="promo-code"
              name="promoCode"
            />
          </div>
        </div>
        {BEWidgetFlags.Flexible && BEWidgetFlags.Flexible === "Y" && (
          <div className="flexiBoxWrapper">
            <div className="flexible-heading top-padding">Flexible Dates</div>
            <div className="input-wrapper">
              <input
                type="checkbox"
                value={person.flexiDate ? person.flexiDate : ""}
                className="checkbox"
                onClick={handleCheckbox}
                name="flexiDate"
              />
            </div>
          </div>
        )}
        {BEWidgetFlags.Multycurrency && BEWidgetFlags.Multycurrency === "Y" && (
          <div className="currencyWrapper">
            <div className="currency-heading">Currency</div>
            <div className="currency-input-wrapper">
              <select
                name="m_currency"
                id="m_currency"
                tabIndex="-1"
                onChange={handleInput}
              >
                {currency.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="buttonWrapper">
          <div>&nbsp;</div>
          <button type="button" onClick={handleCheckAvailability}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </BookingWidget>
  );
};
export default BookingWidgetFlexComponent;
