import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { NavLink } from "react-router-dom";
import media from "../../global/media";
import { WidgetOpen } from "../../global/constants";
import constant from "../../Template/Template11Pages/constant";
import {
  faChevronDown,
  faTimes,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roomTypes } from "../../../hotelData.json";

const NavLayout9Wrapper = styled.div`
  transition: display 0.5s cubic-bezier(0.56, 0.32, 0.43, 0.9);
  background: ${constant.blackColor};

  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 101;
  top: 0;
  left: -100%;

  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */

  .icon {
    display: inline-block;
    font-size: 1em;
    cursor: pointer;
    margin-left: 10px;
    &:after {
      content: "";
      display: block;
      height: 40px;
      width: 1px;
      position: absolute;
      right: -7px;
      top: 0;
      margin-top: -10px;
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .icon-wrapper {
    outline: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-transform: inherit;
    letter-spacing: inherit;
    display: ${(prop) => (prop.navigationToggle === "none" ? "none" : "block")};
    position: absolute;
    color: ${constant.whiteColor};
    font-size: 18px;
    font-weight: 400;
    top: 0em;
    right: 0em;
    z-index: 10;
    padding: 28px;
    line-height: 0;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  ${media.md} {
    width: 100%;
  }
`;
const NavBarWrapper = styled.div`
  padding: 15px;
  color: ${constant.secondaryColor};
  ${media.md} {
    display: flex;
    margin-top: 0;
    align-items: center;
  }
  img {
    height: 80px;
  }
`;

const NavBarUl = styled.ul`
  padding: 15px;
  list-style-type: none;
  margin: 0;
  background: transparent;
  position: relative;
  display: block;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${constant.primaryColor};
  }

  &::-webkit-scrollbar {
    width: 10px;
    z-index: 1000;
    background-color: ${constant.whiteColor};
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${constant.whiteColor};
  }
  ${media.sm} {
    padding: 20px;
  }
  ${media.md} {
    padding: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &.desktopPopupMenu {
    /* display: ${(prop) =>
      prop.navigationToggle === "block" ? "block" : "none"}; */
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: scroll;
    transition: all 0.5ms fade;

    ${media.md} {
      > li {
        width: 100%;
      }
    }
  }
`;
const NavBarLi = styled.li`
  position: relative;
  /* margin-right: 10px;
  padding: 10px; */
  overflow: hidden;

  .faIcon {
    position: absolute;
    right: 10px;
  }
  &.hidden {
    display: none;
  }
  /* ${media.md} {
    display: inline-block;
  } */
  .sublinks {
    position: relative;
    transform: translate(-50%, -50%);
    &:after {
      position: absolute;
      right: 10px;
      content: ${faChevronDown};
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .nav-link {
    text-align: left;
    display: block;
    text-decoration: none;
    font-size: 15px;
    position: relative;
    transition: all 0.4s;
    padding: 1rem;
    color: #fff;
    border: none;
    border-bottom: 1px solid ${constant.borderColor};
    line-height: 1;
    font-weight: 600;
    background: #191919;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      color: ${constant.secondaryColor};
    }
  }
  button {
    cursor: pointer;
    border: none;
    .icon {
      margin-right: 5px;
    }
  }
`;

const NavBarMain = styled.div`
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: calc(100%);
  height: 100%;
  overflow-x: hidden;
  background-color: #191919;
  -webkit-font-smoothing: subpixel-antialiased;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${constant.bgBlack};
  }

  &::-webkit-scrollbar {
    width: 10px;
    z-index: 1000;
    background-color: ${constant.bgBlack};
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${constant.bgBlack};
  }

  ${media.md} {
    width: calc(100%);
  }
`;

const NavBarSubWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  transform: translate3d(100%, 0, 0);
  transition: transform 350ms cubic-bezier(0.55, 0, 0.1, 1);
  z-index: 4;
  background: #191919;
  transition: 0.5s all ease-in-out;

  img {
    height: 80px;
  }
`;

const NavBarSubUl = styled.ul`
  list-style-type: none;
  margin: 0;
  background: #191919;
  position: relative;
  display: block;
  overflow-x: hidden;
  padding: 15px;

  .heading-submenu {
    font-size: 18px !important;
  }
  ${media.sm} {
    padding: 20px;
  }
  ${media.md} {
    padding: 50px;
  }
  .back-menu {
    border: none !important;
  }
`;

const NavBarSubLi = styled.li`
  position: relative;
  margin-right: 10px;
  padding: 2px;
  overflow: hidden;
  padding-left: 10px;
  .nav-link {
    text-align: left;
    display: block;
    text-decoration: none;
    font-size: 15px;
    position: relative;
    transition: all 0.4s;
    padding: 1rem;
    color: #fff;
    border: none;
    border-bottom: 1px solid ${constant.borderColor};
    line-height: 1;
    font-weight: 600;
    background: #191919;
    text-transform: uppercase;
    &:hover {
      color: ${constant.secondaryColor};
    }
  }
  button {
    cursor: pointer;
    border: none;
    color: ${constant.bgBlack} !important;
    .icon {
      margin-right: 5px;
    }
    &:hover {
      color: #fff !important;
    }
  }
`;

const BookNowButton = styled.button`
  position: relative;
  padding: 12px 38px;
  display: block;
  text-align: center;
  border: 2px solid #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  background: ${constant.primaryColor};
  display: block;
  margin-top: 20px;

  color: ${constant.whiteColor};
  border-radius: 5px;

  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    background: ${constant.whiteColor};
    color: ${constant.primaryColor};
  }
`;
const NavbarOverlap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0a0a0a;
  opacity: 0;
  z-index: 100;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease, visibility 0s 0.3s ease;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease;

  &.menu-overlay-active {
    opacity: 0.5;
    visibility: visible;
    transition: opacity 0.3s ease;
  }
`;

const NavLayout11 = ({
  navigationSticky,
  navigationToggle,
  menuLinks,
  onNavClick,
  reviewMinderWidget,
  openSubMenu,
  subPage,
  backMenu,
}) => {
  const roomTypesDetail = roomTypes.roomType;

  let selectedMenuItem = "";
  const accommodationSlugName = menuLinks.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  menuLinks.map(
    (item) =>
      item.subLinks &&
      item.subLinks.map((item1) => {
        if (window.location.pathname === item1.menuSlugName) {
          selectedMenuItem = item.menuSlugName;
        }
      })
  );
  let navHeight = 0;
  useEffect(() => {
    function handleHeight(event) {
      navHeight = document.getElementById("navigation").offsetHeight;
    }
    document.addEventListener("load", handleHeight);
  });

  const onNavExternalClick = (externalLink) => {
    window.open(externalLink, "_blank");
  };
  return (
    <>
      <NavbarOverlap id="navbarOverlap"></NavbarOverlap>
      <NavLayout9Wrapper
        id="navigation"
        navigationToggle={navigationToggle}
        menuCount={menuLinks.length}
        navHeight={navHeight}
      >
        <NavBarMain>
          <NavBarWrapper className={navigationSticky ? "sticky" : ""}>
            <NavBarUl
              navigationToggle={navigationToggle}
              className="desktopPopupMenu"
            >
              {menuLinks.map((item, index) =>
                item.menuSlugName === "/reviews/" ||
                item.menuSlugName === "/review/" ? (
                  reviewMinderWidget === "1" && ( //  to show review Page
                    <NavBarLi key={index}>
                      <NavLink
                        activeClassName="active-link"
                        onClick={() => onNavClick(false)}
                        className={`nav-link ${
                          selectedMenuItem === item.websiteSlugName
                            ? "active-link"
                            : ""
                        }`}
                        to={item.menuSlugName}
                        exact
                      >
                        {item.menuLinkName}
                      </NavLink>
                    </NavBarLi>
                  )
                ) : item.menuExternalLink ? ( //to show external link
                  <NavBarLi key={index}>
                    <NavLink
                      onClick={() => onNavExternalClick(item.menuExternalLink)}
                      to="#"
                      className={`nav-link`}
                    >
                      {item.menuLinkName}
                    </NavLink>
                  </NavBarLi>
                ) : //  to show Accommodation Page
                item.pageId === "2" ? (
                  <NavBarLi key={index}>
                    <NavLink
                      activeClassName="active-link"
                      onClick={() => openSubMenu(item.pageId)}
                      className={`nav-link `}
                      to={item.menuSlugName}
                      exact
                    >
                      {item.menuLinkName}
                      <span className="menu-arrow">
                        {item.pageId === "2" ? (
                          <FontAwesomeIcon
                            icon={faAngleDoubleRight}
                            className="icon faBars"
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </NavLink>
                  </NavBarLi>
                ) : (
                  //  to show other Pages

                  <NavBarLi key={index}>
                    <NavLink
                      activeClassName="active-link"
                      onClick={
                        item.subLinks && item.subLinks.length > 0
                          ? () => openSubMenu(item.MenuLinkOrder)
                          : () => onNavClick(false)
                      }
                      className={`nav-link `}
                      to={item.menuSlugName}
                      exact
                    >
                      {item.menuLinkName}
                      <span>
                        {item.subLinks && item.subLinks.length > 0 ? " >>" : ""}
                      </span>
                    </NavLink>
                  </NavBarLi>
                )
              )}

              <NavBarLi>
                <BookNowButton
                  activeClassName="active-link bookNowButton"
                  // className={`nav-link `}
                  onClick={() => WidgetOpen(true)}
                >
                  book now
                </BookNowButton>
              </NavBarLi>
            </NavBarUl>
          </NavBarWrapper>
          <NavBarSubWrapper id="navBarSub">
            <NavBarSubUl navigationToggle={navigationToggle}>
              <Row>
                <Col xs={12} md={12}>
                  <NavBarSubLi>
                    <button
                      activeClassName="active-link"
                      onClick={() => backMenu()}
                      className="nav-link back-menu menu-arrow"
                    >
                      <FontAwesomeIcon
                        icon={faAngleDoubleLeft}
                        className="icon faBars"
                      />
                      back
                    </button>
                  </NavBarSubLi>
                </Col>
                <Col xs={12} md={12}>
                  {menuLinks.map(
                    (item, index) =>
                      item.pageId === subPage && (
                        <NavBarSubLi>
                          <NavLink
                            activeClassName="active-link"
                            onClick={() => onNavClick(false)}
                            className="nav-link heading-submenu"
                            to={item.menuSlugName}
                          >
                            <strong>All {item.menuLinkName}</strong>
                          </NavLink>
                        </NavBarSubLi>
                      )
                  )}
                </Col>
                {subPage === "2" // ? to show Accommodation Page
                  ? roomTypesDetail.map((item, index) => {
                      return (
                        <Col xs={12} md={12}>
                          <NavBarSubLi key={index}>
                            <NavLink
                              activeClassName="active-link"
                              onClick={() => onNavClick(false)}
                              className={`nav-link`}
                              to={
                                accommodationSlugName[0]["menuSlugName"] +
                                item.RoomTypeName.slugName
                              }
                              exact
                            >
                              {item.RoomTypeName.section}
                            </NavLink>
                          </NavBarSubLi>
                        </Col>
                      );
                    })
                  : //? to show sub Pages
                    menuLinks.map(
                      (item, index) =>
                        item.MenuLinkOrder === subPage &&
                        item.subLinks.map((item1, index) => (
                          <Col xs={12} md={12}>
                            <NavBarSubLi key={index}>
                              <NavLink
                                activeClassName="active-link"
                                onClick={() => onNavClick(false)}
                                className={`nav-link`}
                                to={item1.menuSlugName}
                                exact
                              >
                                {item1.menuLinkName}
                              </NavLink>
                            </NavBarSubLi>
                          </Col>
                        ))
                    )}
              </Row>
            </NavBarSubUl>
          </NavBarSubWrapper>
        </NavBarMain>
        <button
          className="icon-wrapper"
          onClick={() => onNavClick(false)}
          navigationToggle={navigationToggle}
        >
          <FontAwesomeIcon icon={faTimes} className="icon faBars" />
        </button>
      </NavLayout9Wrapper>
    </>
  );
};

export default NavLayout11;
