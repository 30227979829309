import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { NavLink } from "react-router-dom";
import media from "../../global/media";
import { WidgetOpen } from "../../global/constants";
import constant from "../../Template/Template8Pages/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faTimes,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { roomTypes } from "./../../../hotelData.json";

const NavLayout8Wrapper = styled.div`
  transition: display 0.5ms ease-in-out;
  background: #fafafa;
  height: 100%;
  width: 100vw;
  position: fixed;
  z-index: 20;
  top: 0;
  right: -9999px;
  /* overflow-x: hidden; Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */

  /* overflow:hidden; */
  transition: open 0.5s ease-in-out;

  @keyframes open {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .icon-wrapper {
    outline: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-transform: inherit;
    letter-spacing: inherit;
    display: ${(prop) => (prop.navigationToggle === "none" ? "none" : "block")};
    position: absolute;
    color: #707070;
    font-size: 25px;
    top: 1em;
    right: 1em;
    z-index: 10;
    padding: 22px;
    line-height: 0;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* &.faBars {
      width: 16px;
      height: 16px;
      
    } */

    p {
      position: absolute;
      top: 11px;
      left: 60px;
      font-size: 1em;
      text-transform: uppercase;
      color: ${constant.secondaryColor};
      padding-right: 10px;
    }
  }
  .menu-arrow {
    svg {
      font-size: 11px;
      font-weight: lighter;
      margin: 0 10px;
      color: #707070;
    }
  }
`;

const NavBarMain = styled.div`
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: calc(100%);
  height: 100%;
  overflow-x: hidden;
  background-color: #fafafa;
  -webkit-font-smoothing: subpixel-antialiased;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fafafa;
  }

  &::-webkit-scrollbar {
    width: 10px;
    z-index: 1000;
    background-color: ${constant.primaryColor};
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(135, 28, 60);
  }

  ${media.md} {
    width: calc(100%);
  }
`;
const NavBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 30px 10px;
  overflow: auto;
  color: #fafafa;

  ${media.sm} {
    padding: 36px;
  }
`;
const NavBarSubWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 30px 10px;
  overflow: auto;
  transform: translate3d(100%, 0, 0);
  transition: transform 350ms cubic-bezier(0.55, 0, 0.1, 1);
  z-index: 4;
  background: #fafafa;
  transition: 0.5s all ease-in-out;
  ${media.sm} {
    padding: 36px;
  }
`;
const NavBarUl = styled.ul`
  list-style-type: none;
  margin: 0;
  background: #fafafa;
  position: relative;
  display: block;
  overflow-x: hidden;
`;

const NavBarSubUl = styled.ul`
  list-style-type: none;
  margin: 0;
  background: #fafafa;
  position: relative;
  display: block;
  overflow-x: hidden;
  .heading-submenu {
    color: #000 !important;
  }
`;

const NavBarSubLi = styled.li`
  position: relative;
  margin-right: 10px;
  padding: 2px;
  overflow: hidden;
  padding-left: 10px;
  .nav-link {
    text-align: left;
    display: block;
    padding: 6px;
    text-decoration: none;
    color: #707070;
    font-size: 14px;
    letter-spacing: 0.24em;
    line-height: 2.4em;
    text-transform: uppercase;
    position: relative;
    transition: all 0.4s;
    font-weight: 600;
    &:hover {
      opacity: 0.5;
    }
    ${media.sm} {
      font-size: 16px;
    }
  }

  button {
    all: unset;
    cursor: pointer;
    .icon {
      margin-right: 5px;
    }
  }
`;

const NavBarLi = styled.li`
  position: relative;
  margin-right: 10px;
  padding: 2px;
  overflow: hidden;
  padding-left: 10px;
  .faIcon {
    position: absolute;
    right: 10px;
  }
  &.hidden {
    display: none;
  }
  .nav-link {
    text-align: left;
    display: block;
    padding: 6px;
    text-decoration: none;
    color: #707070;
    font-size: 15px;
    letter-spacing: 0.24em;
    line-height: 2.4em;
    text-transform: uppercase;
    position: relative;
    transition: all 0.4s;
    font-weight: 600;
    &:hover {
      opacity: 0.5;
    }
    ${media.sm} {
      font-size: 16px;
    }
  }
`;

const BookNowButton = styled.button`
  position: relative;
  padding: 12px 38px;
  display: block;
  text-align: center;
  border: 2px solid #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  background: rgb(135, 28, 60);

  /* background:transparent; */
  color: #fff;
  border-radius: 300px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const NavLayout8 = ({
  navigationSticky,
  navigationToggle,
  menuLinks,
  onNavClick,
  reviewMinderWidget,
  openSubMenu,
  subPage,
  backMenu,
}) => {
  const accommodationSlugName = menuLinks.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );

  // console.log(accommodationSlugName);

  const roomTypesDetail = roomTypes.roomType;

  let selectedMenuItem = "";

  menuLinks.map(
    (item) =>
      item.subLinks &&
      item.subLinks.map((item1) => {
        if (window.location.pathname === item1.menuSlugName) {
          selectedMenuItem = item.menuSlugName;
        }
      })
  );
  let navHeight = 0;
  useEffect(() => {
    function handleHeight(event) {
      navHeight = document.getElementById("navigation").offsetHeight;
    }
    document.addEventListener("load", handleHeight);
  });

  const onNavExternalClick = (externalLink) => {
    window.open(externalLink, "_blank");
  };

  return (
    <NavLayout8Wrapper
      id="navigation"
      navigationToggle={navigationToggle}
      menuCount={menuLinks.length}
      navHeight={navHeight}
    >
      <Grid>
        <Row>
          <Col xs={12} md={10}>
            <NavBarMain>
              <NavBarWrapper className={navigationSticky ? "sticky" : ""}>
                <NavBarUl
                  navigationToggle={navigationToggle}
                  className="desktopPopupMenu"
                >
                  {menuLinks.map((item, index) =>
                    item.menuSlugName === "/reviews/" ||
                    item.menuSlugName === "/review/" ? (
                      reviewMinderWidget === "1" && ( //  to show review Page
                        <NavBarLi key={index}>
                          <NavLink
                            activeClassName="active-link"
                            onClick={() => onNavClick(false)}
                            className={`nav-link ${
                              selectedMenuItem === item.websiteSlugName
                                ? "active-link"
                                : ""
                            }`}
                            to={item.menuSlugName}
                            exact
                          >
                            {item.menuLinkName}
                          </NavLink>
                        </NavBarLi>
                      )
                    ) : item.menuExternalLink ? ( //to show external link
                      <NavBarLi key={index}>
                        <NavLink
                          onClick={() =>
                            onNavExternalClick(item.menuExternalLink)
                          }
                          to="#"
                          className={`nav-link`}
                        >
                          {item.menuLinkName}
                        </NavLink>
                      </NavBarLi>
                    ) : //  to show Accommodation Page
                    item.pageId === "2" ? (
                      <NavBarLi key={index}>
                        <NavLink
                          activeClassName="active-link"
                          onClick={() => openSubMenu(item.pageId)}
                          className={`nav-link `}
                          to={item.menuSlugName}
                          exact
                        >
                          {item.menuLinkName}
                          <span className="menu-arrow">
                            {item.pageId === "2" ? (
                              <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className="icon faBars"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </NavLink>
                      </NavBarLi>
                    ) : (
                      //  to show other Pages

                      <NavBarLi key={index}>
                        <NavLink
                          activeClassName="active-link"
                          onClick={
                            item.subLinks && item.subLinks.length > 0
                              ? () => openSubMenu(item.MenuLinkOrder)
                              : () => onNavClick(false)
                          }
                          className={`nav-link `}
                          to={item.menuSlugName}
                          exact
                        >
                          {item.menuLinkName}
                          <span>
                            {item.subLinks && item.subLinks.length > 0
                              ? " >>"
                              : ""}
                          </span>
                        </NavLink>
                      </NavBarLi>
                    )
                  )}

                  <NavBarLi>
                    <BookNowButton
                      activeClassName="active-link bookNowButton"
                      // className={`nav-link `}
                      onClick={() => WidgetOpen(true)}
                    >
                      book now
                    </BookNowButton>
                  </NavBarLi>
                </NavBarUl>
              </NavBarWrapper>
              <NavBarSubWrapper id="navBarSub">
                <NavBarSubUl navigationToggle={navigationToggle}>
                  <Row>
                    <Col xs={12} md={12}>
                      <NavBarSubLi>
                        <button
                          activeClassName="active-link"
                          onClick={() => backMenu()}
                          className="nav-link menu-arrow"
                        >
                          <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            className="icon faBars"
                          />
                          back
                        </button>
                      </NavBarSubLi>
                    </Col>
                    <Col xs={12} md={12}>
                      {menuLinks.map(
                        (item, index) =>
                          item.pageId === subPage && (
                            <NavBarSubLi>
                              <NavLink
                                activeClassName="active-link"
                                onClick={() => onNavClick(false)}
                                className="nav-link heading-submenu"
                                to={item.menuSlugName}
                              >
                                <strong>All {item.menuLinkName}</strong>
                              </NavLink>
                            </NavBarSubLi>
                          )
                      )}
                    </Col>
                    {subPage === "2" // ? to show Accommodation Page
                      ? roomTypesDetail.map((item, index) => {
                          return (
                            <Col xs={12} md={12}>
                              <NavBarSubLi key={index}>
                                <NavLink
                                  activeClassName="active-link"
                                  onClick={() => onNavClick(false)}
                                  className={`nav-link`}
                                  to={
                                    accommodationSlugName[0]["menuSlugName"] +
                                    item.RoomTypeName.slugName
                                  }
                                  exact
                                >
                                  {item.RoomTypeName.section}
                                </NavLink>
                              </NavBarSubLi>
                            </Col>
                          );
                        })
                      : //? to  s
                        menuLinks.map(
                          (item, index) =>
                            item.MenuLinkOrder === subPage &&
                            item.subLinks.map((item1, index) => (
                              <Col xs={12} md={12}>
                                <NavBarSubLi key={index}>
                                  <NavLink
                                    activeClassName="active-link"
                                    onClick={() => onNavClick(false)}
                                    className={`nav-link`}
                                    to={item1.menuSlugName}
                                    exact
                                  >
                                    {item1.menuLinkName}
                                  </NavLink>
                                </NavBarSubLi>
                              </Col>
                            ))
                        )}
                  </Row>
                </NavBarSubUl>
              </NavBarSubWrapper>
            </NavBarMain>
          </Col>
        </Row>
        <button
          className="icon-wrapper"
          onClick={() => onNavClick(false)}
          navigationToggle={navigationToggle}
        >
          <FontAwesomeIcon icon={faTimes} className="icon faBars" />
        </button>
        {/* <BookNowButton 
          className="booknow-nav"
          navigationToggle={navigationToggle} 
        >
          Book Now
        </BookNowButton> */}
      </Grid>
    </NavLayout8Wrapper>
  );
};

export default NavLayout8;
