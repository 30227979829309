import React from "react";
import styled from "styled-components";
import NavLayout1 from "./layouts/NavLayout1";
import NavLayout2 from "./layouts/NavLayout2";
import NavLayout3 from "./layouts/NavLayout3";
import NavLayout4 from "./layouts/NavLayout4";
import NavLayout5 from "./layouts/NavLayout5";
import NavLayout6 from "./layouts/NavLayout6";
import NavLayout7 from "./layouts/NavLayout7";
import NavLayout8 from "./layouts/NavLayout8";
import NavLayout9 from "./layouts/NavLayout9";
import NavLayout10 from "./layouts/NavLayout10";
import NavLayout11 from "./layouts/NavLayout11";
import NavLayout12 from "./layouts/NavLayout12";

const NavigationWrapper = styled.div``;

const Navigation = ({
  navigationSticky,
  templateId,
  navigationToggle,
  menuLinks,
  onNavClick,
  propertyId,
  propertyDetails,
  reviewMinderWidget,
  openSubMenu,
  subPage,
  backMenu,
}) => {
  // console.log(templateId);
  return (
    <NavigationWrapper>
      {templateId === "1" && (
        <NavLayout1
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          propertyId={propertyId}
        />
      )}
      {templateId === "2" && (
        <NavLayout2
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          propertyId={propertyId}
        />
      )}
      {templateId === "3" && (
        <NavLayout3
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          propertyId={propertyId}
          propertyDetails={propertyDetails}
        />
      )}
      {templateId === "4" && (
        <NavLayout4
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          propertyId={propertyId}
          propertyDetails={propertyDetails}
        />
      )}
      {templateId === "5" && (
        <NavLayout5
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          propertyId={propertyId}
          propertyDetails={propertyDetails}
        />
      )}
      {templateId === "6" && (
        <NavLayout6
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          propertyId={propertyId}
          propertyDetails={propertyDetails}
        />
      )}
      {templateId === "7" && (
        <NavLayout7
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
        />
      )}
      {templateId === "8" && (
        <NavLayout8
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
          openSubMenu={openSubMenu}
          subPage={subPage}
          backMenu={backMenu}
        />
      )}
      {templateId === "9" && (
        <NavLayout9
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
          openSubMenu={openSubMenu}
          subPage={subPage}
          backMenu={backMenu}
        />
      )}
      {templateId === "10" && (
        <NavLayout10
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
          openSubMenu={openSubMenu}
          subPage={subPage}
          backMenu={backMenu}
        />
      )}
      {templateId === "11" && (
        <NavLayout11
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
          openSubMenu={openSubMenu}
          subPage={subPage}
          backMenu={backMenu}
        />
      )}
      {templateId === "12" && (
        <NavLayout12
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
          openSubMenu={openSubMenu}
          subPage={subPage}
          backMenu={backMenu}
        />
      )}
      {templateId === "13" && (
        <NavLayout12
          onNavClick={onNavClick}
          navigationSticky={navigationSticky}
          navigationToggle={navigationToggle}
          menuLinks={menuLinks}
          reviewMinderWidget={reviewMinderWidget}
          openSubMenu={openSubMenu}
          subPage={subPage}
          backMenu={backMenu}
        />
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
